import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import styled from "styled-components"
import Loader from "../../components/loader"
import { colors } from "../../components/siteVars"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

const LoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlannerIndexPage = () => {
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    if (state.user.permissions) {
      navigate("/planner/programs")
    } else {
      navigate("/login")
    }
  }, [])
  return (
    <Layout>
      <LoaderContainer>
        <Loader dualRing color={colors.mediumGrey} />
      </LoaderContainer>
    </Layout>
  )
}

export default PlannerIndexPage
